var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("e-sidebar", {
        attrs: {
          title: _vm.getTitle,
          show: _vm.showSidebar,
          fetching: _vm.fetching,
          saving: _vm.saving,
          width: "600px",
          "hide-save-button": _vm.isReadOnly,
        },
        on: { save: _vm.onSaveDeliveryConfig, hidden: _vm.hideSidebar },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  [
                    _c(
                      "b-card-actions",
                      {
                        attrs: {
                          "action-collapse": "",
                          title: _vm.$t("Dados"),
                        },
                      },
                      [
                        _c(
                          "FormulateForm",
                          {
                            ref: "deliveryConfigForm",
                            attrs: { name: "deliveryConfigForm" },
                          },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { md: "6" } },
                                  [
                                    _c("FormulateInput", {
                                      attrs: {
                                        id: "delivery_config-store",
                                        name: "store",
                                        type: "label",
                                        filter: "storeName",
                                        label: _vm.$t("Loja"),
                                      },
                                      model: {
                                        value: _vm.localDeliveryConfig,
                                        callback: function ($$v) {
                                          _vm.localDeliveryConfig = $$v
                                        },
                                        expression: "localDeliveryConfig",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { md: "6" } },
                                  [
                                    _c("FormulateInput", {
                                      attrs: {
                                        id: "delivery_config-email",
                                        name: "store",
                                        type: "label",
                                        label: _vm.$t("Email do delivery"),
                                      },
                                      model: {
                                        value:
                                          _vm.localDeliveryConfig.emailDelivery,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.localDeliveryConfig,
                                            "emailDelivery",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "localDeliveryConfig.emailDelivery",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { md: "6" } },
                                  [
                                    _vm.isReadOnly
                                      ? _c("FormulateInput", {
                                          attrs: {
                                            id: "delivery_config-minDeliveryScheduleMinutes",
                                            name: "minDeliveryScheduleMinutes",
                                            type: "label",
                                            label: _vm.$t(
                                              "Tempo mínimo para entrega"
                                            ),
                                            instruction: _vm.$t(
                                              "Tempo mínimo em minutos para iniciar a entrega de pedidos."
                                            ),
                                          },
                                          model: {
                                            value:
                                              _vm.localDeliveryConfig
                                                .minDeliveryScheduleMinutes,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.localDeliveryConfig,
                                                "minDeliveryScheduleMinutes",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "localDeliveryConfig.minDeliveryScheduleMinutes",
                                          },
                                        })
                                      : _c("FormulateInput", {
                                          staticClass: "required",
                                          attrs: {
                                            id: "delivery_config-minDeliveryScheduleMinutes",
                                            name: "minDeliveryScheduleMinutes",
                                            type: "text-number",
                                            label: _vm.$t(
                                              "Tempo mínimo para entrega"
                                            ),
                                            instruction: _vm.$t(
                                              "Tempo mínimo em minutos para iniciar a entrega de pedidos."
                                            ),
                                            validation: "required",
                                          },
                                          model: {
                                            value:
                                              _vm.localDeliveryConfig
                                                .minDeliveryScheduleMinutes,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.localDeliveryConfig,
                                                "minDeliveryScheduleMinutes",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "localDeliveryConfig.minDeliveryScheduleMinutes",
                                          },
                                        }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <b-row content="center">
    <b-col>
      <FormulateInput
        id="day-label"
        type="label"
        :value="$t(daysMap[dayName])"
      />
    </b-col>
    <b-col>
      <FormulateInput
        type="switch"
        :disabled="enableToEdit"
        @change="$emit('day-name', { value: $event, dayName })"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
  },

  props: {
    dayName: {
      type: String,
      required: true,
    },
    enableToEdit: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      daysMap: {
        sunday: 'domingo',
        monday: 'segunda-feira',
        tuesday: 'terça-feira',
        wednesday: 'quarta-feira',
        thursday: 'quinta-feira',
        friday: 'sexta-feira',
        saturday: 'sábado',
      },
    }
  },
}
</script>

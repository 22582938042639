<template>
  <div>
    <e-sidebar
      :title="getTitle"
      :show="showSidebar"
      :fetching="fetching"
      :saving="saving"
      width="600px"
      :hide-save-button="isReadOnly"
      @save="onSaveDeliveryConfig"
      @hidden="hideSidebar"
    >
      <template #content>
        <div>
          <b-card-actions
            action-collapse
            :title="$t('Dados')"
          >
            <FormulateForm
              ref="deliveryConfigForm"
              name="deliveryConfigForm"
            >
              <b-row>
                <b-col md="6">
                  <FormulateInput
                    id="delivery_config-store"
                    v-model="localDeliveryConfig"
                    name="store"
                    type="label"
                    filter="storeName"
                    :label="$t('Loja')"
                  />
                </b-col>
                <b-col md="6">
                  <FormulateInput
                    id="delivery_config-email"
                    v-model="localDeliveryConfig.emailDelivery"
                    name="store"
                    type="label"
                    :label="$t('Email do delivery')"
                  />
                </b-col>
                <b-col md="6">
                  <FormulateInput
                    v-if="isReadOnly"
                    id="delivery_config-minDeliveryScheduleMinutes"
                    v-model="localDeliveryConfig.minDeliveryScheduleMinutes"
                    name="minDeliveryScheduleMinutes"
                    type="label"
                    :label="$t('Tempo mínimo para entrega')"
                    :instruction="$t('Tempo mínimo em minutos para iniciar a entrega de pedidos.')"
                  />
                  <FormulateInput
                    v-else
                    id="delivery_config-minDeliveryScheduleMinutes"
                    v-model="localDeliveryConfig.minDeliveryScheduleMinutes"
                    name="minDeliveryScheduleMinutes"
                    type="text-number"
                    :label="$t('Tempo mínimo para entrega')"
                    :instruction="$t('Tempo mínimo em minutos para iniciar a entrega de pedidos.')"
                    class="required"
                    validation="required"
                  />
                </b-col>
              </b-row>
            </FormulateForm>
          </b-card-actions>
        </div>
      </template>
    </e-sidebar>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import { ESidebar } from '@/views/components'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'

const getInitialLocalDeliveryConfig = () => ({
  id: null,
  minDeliveryScheduleMinutes: 0,
})

export default {
  components: {
    BCol,
    BRow,
    ESidebar,
    BCardActions,
  },

  props: {
    storeId: {
      type: Number,
      default: null,
    },
    store: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      showSidebar: false,
      fetching: false,
      saving: false,
      isReadOnly: false,
      localStoreId: null,
      localDeliveryConfig: getInitialLocalDeliveryConfig(),
    }
  },

  computed: {
    getTitle() {
      return this.isReadOnly ? this.$t('Config. Delivery') : this.$t('Editar Config. Delivery')
    },
  },

  watch: {
    store(val) {
      this.localDeliveryConfig = val
    },
    storeId(val) {
      this.localStoreId = val
    },
    async localStoreId(val) {
      if (val) {
        try {
          this.fetching = true
          const { data: localDeliveryConfig } = await this.$http.get(
            `/api/store/${this.localStoreId}`
          )
          this.localDeliveryConfig = localDeliveryConfig
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      } else {
        this.localDeliveryConfig = getInitialLocalDeliveryConfig()
      }
    },
  },

  methods: {
    async show({ id, item } = {}) {
      if (id) this.localStoreId = id
      if (item && typeof item === 'object') this.localDeliveryConfig = { ...item }
      this.showSidebar = true
    },

    async showReadOnly({ id, item } = {}) {
      this.isReadOnly = true
      this.show({ id, item })
    },

    cleanLocalData() {
      this.localDeliveryConfig = getInitialLocalDeliveryConfig()
      this.localStoreId = null
      this.isReadOnly = false
    },

    hideSidebar() {
      this.cleanLocalData()
      this.showSidebar = false
    },

    async onSaveDeliveryConfig() {
      this.$refs.deliveryConfigForm.showErrors()
      if (this.$refs.deliveryConfigForm.hasErrors) {
        this.showInvalidDataMessage()
        return
      }

      try {
        this.saving = true
        const { id, minDeliveryScheduleMinutes } = this.localDeliveryConfig

        await this.$http.patch(`/api/stores/delivery/${id}`, {
          minDeliveryScheduleMinutes,
        })

        this.showSuccess({
          message: this.$t('Atualização realizada com sucesso'),
        })
        this.$emit('after-save')
        this.hideSidebar()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },
  },
}
</script>

<style></style>

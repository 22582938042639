var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "e-filters",
        {
          attrs: { title: _vm.$t("Filtros - Lojas"), searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "1" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "store-id",
                      type: "number",
                      label: _vm.$t("Id"),
                    },
                    model: {
                      value: _vm.filters.id,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "id", $$v)
                      },
                      expression: "filters.id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "type",
                      type: "vue-select",
                      label: _vm.$t("Tipo"),
                      options: _vm.storeTypes(),
                      placeholder: _vm.$t("Selecione"),
                    },
                    model: {
                      value: _vm.filters.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "type", $$v)
                      },
                      expression: "filters.type",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "region",
                      type: "vue-select",
                      label: _vm.$t("Região"),
                      options: _vm.storeRegions(),
                      placeholder: _vm.$t("Selecione"),
                    },
                    model: {
                      value: _vm.filters.region,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "region", $$v)
                      },
                      expression: "filters.region",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "province",
                      type: "vue-select",
                      label: _vm.$t("Estado"),
                      options: _vm.localProvinces,
                    },
                    model: {
                      value: _vm.filters.province,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "province", $$v)
                      },
                      expression: "filters.province",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "city",
                      type: "text",
                      label: _vm.$t("Cidade"),
                    },
                    model: {
                      value: _vm.filters.city,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "city", $$v)
                      },
                      expression: "filters.city",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card-actions",
        {
          ref: "table-card",
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.getData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c("b-table", {
                    ref: "stores-table",
                    staticClass: "bordered sotre-select-row",
                    attrs: {
                      id: "stores-table",
                      "tbody-tr-class": _vm.rowClass,
                      responsive: "",
                      striped: "",
                      "show-empty": "",
                      "empty-text": _vm.getEmptyTableMessage(
                        _vm.$tc("STORE.NAME"),
                        "female"
                      ),
                      items: _vm.stores,
                      fields: _vm.fields,
                      "no-local-sorting": "",
                      "sort-by": _vm.sorting.sortBy,
                      "sort-desc": _vm.sorting.sortDesc,
                    },
                    on: {
                      "update:sortBy": function ($event) {
                        return _vm.$set(_vm.sorting, "sortBy", $event)
                      },
                      "update:sort-by": function ($event) {
                        return _vm.$set(_vm.sorting, "sortBy", $event)
                      },
                      "update:sortDesc": function ($event) {
                        return _vm.$set(_vm.sorting, "sortDesc", $event)
                      },
                      "update:sort-desc": function ($event) {
                        return _vm.$set(_vm.sorting, "sortDesc", $event)
                      },
                      "sort-changed": _vm.getData,
                      "row-clicked": _vm.onRowClickToConfigDelivery,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "head(bulkConfig)",
                        fn: function () {
                          return [
                            _c(
                              "p",
                              { staticClass: "ml-1" },
                              [
                                _c("e-instructions", {
                                  attrs: {
                                    title: _vm.$t("Alteração em massa"),
                                    instruction: _vm.$t(
                                      "Selecione pelo menos um registro para habilitar o botão de alteração em massa no canto inferior direto da tela."
                                    ),
                                  },
                                }),
                                _c("FormulateInput", {
                                  staticClass: "mt-1",
                                  attrs: {
                                    id: "input-check_all",
                                    type: "e-checkbox",
                                    title: _vm.$t(
                                      "Marca ou desmarca todos os itens desta página."
                                    ),
                                  },
                                  on: {
                                    change: function (val) {
                                      return _vm.onSelectAllToDeliveryConfig(
                                        val
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.allCheckedToDeliveryConfig,
                                    callback: function ($$v) {
                                      _vm.allCheckedToDeliveryConfig = $$v
                                    },
                                    expression: "allCheckedToDeliveryConfig",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "cell(bulkConfig)",
                        fn: function (row) {
                          return [
                            _c(
                              "div",
                              { staticClass: "check-td" },
                              [
                                _c("FormulateInput", {
                                  attrs: { type: "e-checkbox" },
                                  on: {
                                    change: function (val) {
                                      return _vm.onSelectStoreToConfigDelivery(
                                        val,
                                        row.item
                                      )
                                    },
                                  },
                                  model: {
                                    value: row.item.storeSelected,
                                    callback: function ($$v) {
                                      _vm.$set(row.item, "storeSelected", $$v)
                                    },
                                    expression: "row.item.storeSelected",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(actions)",
                        fn: function (row) {
                          return [
                            _c("e-grid-actions", {
                              attrs: {
                                "show-delete": false,
                                "show-update": _vm.canShowUpdateAction,
                                "show-read-only": false,
                              },
                              on: {
                                update: function ($event) {
                                  return _vm.onEdit(row.item)
                                },
                                "read-only": function ($event) {
                                  return _vm.onShowReadOnly(row.item)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "6" } },
                        [
                          _c("e-pagination-summary", {
                            attrs: {
                              "current-page": _vm.paging.currentPage,
                              "per-page": _vm.paging.pageSize,
                              total: _vm.paging.rowCount,
                              "total-on-page": _vm.paging.rowsInCurrentPage,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "6" } },
                        [
                          _c("b-pagination", {
                            attrs: {
                              align: "right",
                              "total-rows": _vm.paging.rowCount,
                              "per-page": _vm.paging.pageSize,
                              "aria-controls": "product-table",
                            },
                            on: { change: _vm.pageChange },
                            model: {
                              value: _vm.paging.currentPage,
                              callback: function ($$v) {
                                _vm.$set(_vm.paging, "currentPage", $$v)
                              },
                              expression: "paging.currentPage",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.hasBulkDeliveryConfig
        ? _c("fab", {
            attrs: {
              "main-tooltip": _vm.$t("Ações"),
              "main-icon": "keyboard_command_key",
              "fixed-tooltip": true,
              actions: _vm.fabActions,
            },
            on: {
              "clean-bulk-delivery-config": _vm.cleanBulkDeliveryConfig,
              "confirm-bulk-delivery-config": _vm.showBulkDeliveryConfigModal,
            },
          })
        : _vm._e(),
      _c("delivery-store-config-list-modal", {
        ref: "deliveryStoreConfigListModal",
        on: {
          "after-confirm": function (ref) {
            var itemList = ref.itemList

            return _vm.onAfterChangeDeliveryConfigs(itemList)
          },
          "remove-store": function (item) {
            _vm.removeStoresThatWillBeConfigured(item)
            _vm.getData()
          },
        },
      }),
      _c("delivery-store-config-sidebar", {
        ref: "deliveryStoreConfigSidebar",
        on: { "after-save": _vm.getData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
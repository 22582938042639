var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "zero_rate",
        title: _vm.$t("Alterações de Delivery"),
        centered: "",
        size: "xl",
        "no-close-on-backdrop": "",
        "hide-header-close": "",
        "hide-footer": "",
      },
      on: { hidden: _vm.cleanModalData },
    },
    [
      _vm.loading
        ? _c("e-spinner")
        : _c(
            "b-container",
            { attrs: { fluid: "", "show-loading": _vm.saving } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("b-alert", {
                        staticStyle: {
                          padding: "15px",
                          "text-align": "center",
                        },
                        attrs: { variant: "danger", show: "" },
                        domProps: {
                          textContent: _vm._s(
                            "ATENÇÃO: Todas as lojas listadas serão alteradas com as configurações selecionadas"
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-card-actions",
                                    {
                                      attrs: {
                                        "show-loading": _vm.saving,
                                        title: _vm.$t("Configurações Gerais"),
                                        "no-actions": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        {
                                          staticStyle: { position: "relative" },
                                        },
                                        [
                                          _c("FormulateInput", {
                                            staticClass: "left-switch",
                                            attrs: { type: "switch" },
                                            model: {
                                              value:
                                                _vm.localSwitchs
                                                  .MinDeliveryScheduleMinutes
                                                  .isActive,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.localSwitchs
                                                    .MinDeliveryScheduleMinutes,
                                                  "isActive",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "localSwitchs.MinDeliveryScheduleMinutes.isActive",
                                            },
                                          }),
                                          _c(
                                            "b-col",
                                            [
                                              _c("FormulateInput", {
                                                attrs: {
                                                  id: "min-delivery-schedule-minutes",
                                                  disabled:
                                                    !_vm.localSwitchs
                                                      .MinDeliveryScheduleMinutes
                                                      .isActive,
                                                  type: "text-number",
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "label",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              id: "label",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "e-instructions",
                                                              {
                                                                staticClass:
                                                                  "instruction",
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "0 !important",
                                                                },
                                                                attrs: {
                                                                  title:
                                                                    _vm.$t(
                                                                      "Instruções"
                                                                    ),
                                                                  "container-class":
                                                                    "position-absolute",
                                                                  instruction:
                                                                    _vm.$t(
                                                                      "Tempo mínimo em minutos para iniciar a entrega de pedidos."
                                                                    ),
                                                                },
                                                              }
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "Tempo mínimo para entrega"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ]),
                                                model: {
                                                  value:
                                                    _vm.localSwitchs
                                                      .MinDeliveryScheduleMinutes
                                                      .value,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.localSwitchs
                                                        .MinDeliveryScheduleMinutes,
                                                      "value",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "localSwitchs.MinDeliveryScheduleMinutes.value",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticStyle: { position: "relative" },
                                        },
                                        [
                                          _c("FormulateInput", {
                                            staticClass: "left-switch",
                                            attrs: { type: "switch" },
                                            model: {
                                              value:
                                                _vm.localSwitchs
                                                  .zeroRateMinValue.isActive,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.localSwitchs
                                                    .zeroRateMinValue,
                                                  "isActive",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "localSwitchs.zeroRateMinValue.isActive",
                                            },
                                          }),
                                          _c(
                                            "b-col",
                                            [
                                              _c("FormulateInput", {
                                                attrs: {
                                                  id: "min-zero-rate-value",
                                                  disabled:
                                                    !_vm.localSwitchs
                                                      .zeroRateMinValue
                                                      .isActive,
                                                  type: "text-number",
                                                  currency: "R$",
                                                  precision: 2,
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "label",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              id: "label",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "e-instructions",
                                                              {
                                                                staticClass:
                                                                  "instruction",
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "0 !important",
                                                                },
                                                                attrs: {
                                                                  title:
                                                                    _vm.$t(
                                                                      "Instruções"
                                                                    ),
                                                                  "container-class":
                                                                    "position-absolute",
                                                                  instruction:
                                                                    _vm.$t(
                                                                      "TValor mínimo de pedido para taxa zero."
                                                                    ),
                                                                },
                                                              }
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "Valor min. p/ taxa zero"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ]),
                                                model: {
                                                  value:
                                                    _vm.localSwitchs
                                                      .zeroRateMinValue.value,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.localSwitchs
                                                        .zeroRateMinValue,
                                                      "value",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "localSwitchs.zeroRateMinValue.value",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-card-actions",
                                    {
                                      staticStyle: { position: "relative" },
                                      attrs: {
                                        "show-loading": _vm.saving,
                                        title: _vm.$t(
                                          "Ativar/desativar taxa zero"
                                        ),
                                        "no-actions": "",
                                      },
                                    },
                                    [
                                      _c("FormulateInput", {
                                        staticStyle: {
                                          position: "absolute",
                                          top: "20px",
                                          left: "278px",
                                        },
                                        attrs: { type: "switch" },
                                        model: {
                                          value:
                                            _vm.localSwitchs.zeroRateByDays
                                              .isActive,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.localSwitchs.zeroRateByDays,
                                              "isActive",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "localSwitchs.zeroRateByDays.isActive",
                                        },
                                      }),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            [
                                              _vm._l(
                                                _vm.daysOfWeek,
                                                function (dayOfWeek) {
                                                  return [
                                                    _c(
                                                      "config-delivery-by-stores-list",
                                                      {
                                                        key: dayOfWeek,
                                                        attrs: {
                                                          "enable-to-edit":
                                                            !_vm.localSwitchs
                                                              .zeroRateByDays
                                                              .isActive,
                                                          "day-name": dayOfWeek,
                                                          align: "center",
                                                          "no-actions": "",
                                                        },
                                                        on: {
                                                          "day-name":
                                                            _vm.updateConfig,
                                                        },
                                                      }
                                                    ),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-card",
                            {
                              attrs: {
                                title: "Lojas selecionadas para alteração",
                              },
                            },
                            [
                              _c(
                                "b-list-group",
                                _vm._l(
                                  _vm.storesReceivedInBulkPaginated,
                                  function (store) {
                                    return _c(
                                      "b-list-group-item",
                                      {
                                        key: store.id,
                                        staticClass: "list-item-group",
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(store.companyName) + " "
                                        ),
                                        _c("e-button", {
                                          attrs: {
                                            variant: "danger",
                                            icon: "x-circle",
                                            title: "cancelar",
                                            size: "sm",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deletePrice(store)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                              _c(
                                "b-row",
                                { staticClass: "mt-4" },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c("e-pagination-summary", {
                                        attrs: {
                                          "current-page":
                                            _vm.localPaging.currentPage,
                                          "per-page": _vm.localPaging.pageSize,
                                          total: _vm.localPaging.rowCount,
                                          "total-on-page":
                                            _vm.localPaging.rowsInCurrentPage,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c("b-pagination", {
                                        attrs: {
                                          align: "right",
                                          "total-rows":
                                            _vm.localPaging.rowCount,
                                          "per-page": _vm.localPaging.pageSize,
                                          "aria-controls":
                                            "stores-to-config-list",
                                        },
                                        on: { change: _vm.pageChange },
                                        model: {
                                          value: _vm.localPaging.currentPage,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.localPaging,
                                              "currentPage",
                                              $$v
                                            )
                                          },
                                          expression: "localPaging.currentPage",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-1 justify-content-end" },
                [
                  _c(
                    "b-col",
                    { staticClass: "d-flex justify-content-end" },
                    [
                      _c("e-button", {
                        staticClass: "mr-1",
                        attrs: {
                          id: "modal_receive_installment-btn_cancel",
                          variant: "outline-primary",
                          text: _vm.$t("Cancelar"),
                          "text-shortcuts": ["ESC"],
                        },
                        on: { click: _vm.hideModal },
                      }),
                      _c("e-button", {
                        attrs: {
                          id: "modal_store_zero_rate-btn_confirm",
                          variant: "primary",
                          icon: "gear-fill",
                          text: _vm.$t("Aplicar Configurações"),
                          busy: _vm.saving,
                        },
                        on: { click: _vm.onConfirm },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <e-filters
      :title="$t('Filtros - Lojas')"
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <b-row>
        <b-col md="1">
          <FormulateInput
            id="store-id"
            v-model="filters.id"
            type="number"
            :label="$t('Id')"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="type"
            v-model="filters.type"
            type="vue-select"
            :label="$t('Tipo')"
            :options="storeTypes()"
            :placeholder="$t('Selecione')"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="region"
            v-model="filters.region"
            type="vue-select"
            :label="$t('Região')"
            :options="storeRegions()"
            :placeholder="$t('Selecione')"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="province"
            v-model="filters.province"
            type="vue-select"
            :label="$t('Estado')"
            :options="localProvinces"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="city"
            v-model="filters.city"
            type="text"
            :label="$t('Cidade')"
          />
        </b-col>
      </b-row>
    </e-filters>

    <b-card-actions
      ref="table-card"
      action-refresh
      :show-loading="fetching"
      @refresh="getData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-row>
        <b-col md="12">
          <b-table
            id="stores-table"
            ref="stores-table"
            class="bordered sotre-select-row"
            :tbody-tr-class="rowClass"
            responsive
            striped
            show-empty
            :empty-text="getEmptyTableMessage($tc('STORE.NAME'), 'female')"
            :items="stores"
            :fields="fields"
            no-local-sorting
            :sort-by.sync="sorting.sortBy"
            :sort-desc.sync="sorting.sortDesc"
            @sort-changed="getData"
            @row-clicked="onRowClickToConfigDelivery"
          >
            <template #head(bulkConfig)>
              <p class="ml-1">
                <e-instructions
                  :title="$t('Alteração em massa')"
                  :instruction="
                    $t(
                      'Selecione pelo menos um registro para habilitar o botão de alteração em massa no canto inferior direto da tela.'
                    )
                  "
                />
                <FormulateInput
                  id="input-check_all"
                  v-model="allCheckedToDeliveryConfig"
                  type="e-checkbox"
                  class="mt-1"
                  :title="$t('Marca ou desmarca todos os itens desta página.')"
                  @change="val => onSelectAllToDeliveryConfig(val)"
                />
              </p>
            </template>
            <template #cell(bulkConfig)="row">
              <div class="check-td">
                <FormulateInput
                  v-model="row.item.storeSelected"
                  type="e-checkbox"
                  @change="val => onSelectStoreToConfigDelivery(val, row.item)"
                />
              </div>
            </template>
            <template #cell(actions)="row">
              <e-grid-actions
                :show-delete="false"
                :show-update="canShowUpdateAction"
                :show-read-only="false"
                @update="onEdit(row.item)"
                @read-only="onShowReadOnly(row.item)"
              />
            </template>
          </b-table>
          <b-row>
            <b-col md="6">
              <e-pagination-summary
                :current-page="paging.currentPage"
                :per-page="paging.pageSize"
                :total="paging.rowCount"
                :total-on-page="paging.rowsInCurrentPage"
              />
            </b-col>
            <b-col md="6">
              <b-pagination
                v-model="paging.currentPage"
                align="right"
                :total-rows="paging.rowCount"
                :per-page="paging.pageSize"
                aria-controls="product-table"
                @change="pageChange"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card-actions>

    <fab
      v-if="hasBulkDeliveryConfig"
      :main-tooltip="$t('Ações')"
      main-icon="keyboard_command_key"
      :fixed-tooltip="true"
      :actions="fabActions"
      @clean-bulk-delivery-config="cleanBulkDeliveryConfig"
      @confirm-bulk-delivery-config="showBulkDeliveryConfigModal"
    />

    <delivery-store-config-list-modal
      ref="deliveryStoreConfigListModal"
      @after-confirm="({ itemList }) => onAfterChangeDeliveryConfigs(itemList)"
      @remove-store="
        item => {
          removeStoresThatWillBeConfigured(item)
          getData()
        }
      "
    />

    <delivery-store-config-sidebar
      ref="deliveryStoreConfigSidebar"
      @after-save="getData"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { BTable, BPagination, BRow, BCol } from 'bootstrap-vue'
import { EPageSizeSelector, EPaginationSummary, EFilters } from '@/views/components'
import { downloader, province, statusTypes, storeDomains, translate } from '@/mixins'
import EGridActions from '@/views/components/EGridActions.vue'
import EInstructions from '@/views/components/EInstructions.vue'
import Fab from '@/views/components/FAB.vue'
import DeliveryStoreConfigSidebar from './DeliveryStoreConfigSidebar.vue'
import DeliveryStoreConfigListModal from './components/DeliveryStoreConfigListModal.vue'

export default {
  components: {
    BCardActions,
    BTable,
    BPagination,
    BRow,
    BCol,
    EPageSizeSelector,
    EPaginationSummary,
    EFilters,
    EGridActions,
    DeliveryStoreConfigSidebar,
    EInstructions,
    Fab,
    DeliveryStoreConfigListModal,
  },

  mixins: [downloader, province, storeDomains, statusTypes, translate],

  data() {
    return {
      fetching: false,
      loading: false,
      exporting: false,
      allCheckedToDeliveryConfig: false,
    }
  },

  computed: {
    ...mapGetters('app', ['statesWithStore']),
    ...mapState('pages/sale/deliveryStoreConfig', {
      stores: 'stores',
      paging: 'paging',
      sorting: 'sorting',
      filters: 'filters',
      selectedStores: 'selectedStores',
    }),
    hasBulkDeliveryConfig() {
      return this.selectedStores.length > 0
    },

    localProvinces() {
      return this.provinces().filter(
        provinceItem => this.statesWithStore.indexOf(provinceItem.value) >= 0
      )
    },

    canShowUpdateAction() {
      return this.$can('UpdateDeliveryConfig', 'Store')
    },

    countStoresForBulkConfiguration() {
      return this.selectedStores.length
    },

    fabActions() {
      return [
        {
          name: 'clean-bulk-delivery-config',
          icon: 'backspace',
          color: 'red',
          tooltip: `${this.$t('Limpar configuração em massa')} (${
            this.countStoresForBulkConfiguration
          })`,
          hide: !this.hasBulkDeliveryConfig,
        },
        {
          name: 'confirm-bulk-delivery-config',
          icon: 'settings',
          color: 'green',
          tooltip: `${this.$t('Realizar configuração em massa')} (${
            this.countStoresForBulkConfiguration
          })`,
          hide: !this.hasBulkDeliveryConfig,
        },
      ].filter(act => !act.hide)
    },

    fields() {
      return [
        {
          label: this.$t('Configuração em massa'),
          key: 'bulkConfig',
          tdClass: 'text-center',
          thStyle: { minWidth: '50px', width: '50px' },
        },
        {
          key: 'actions',
          label: this.$t('Ações'),
          class: 'text-center',
          thStyle: { width: '100px' },
          hide: !this.canShowUpdateAction,
        },
        {
          key: 'store',
          label: this.$t('Loja'),
          thStyle: { width: '300px', minWidth: '300px' },
          formatter: (value, key, item) => this.$options.filters.storeName(item) || '-',
          sortKey: 'id',
          sortable: true,
        },
        {
          key: 'minDeliveryScheduleMinutes',
          label: this.$t('Tempo Mínimo para entrega (Minutos)'),
          tdClass: 'text-center',
          thStyle: { width: '120px', minWidth: '120px' },
        },
        {
          key: 'type',
          label: this.$t('Tipo'),
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          formatter: value => this.storeTypesLabel[value] || '-',
          sortable: true,
        },
        {
          key: 'address.province',
          label: this.$t('Estado'),
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          formatter: value => value || '-',
          sortable: true,
        },
        {
          key: 'region',
          label: this.$t('Região'),
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          formatter: value => value || '-',
          sortable: true,
        },
        {
          key: 'address.city',
          label: this.$t('Cidade'),
          sortable: true,
        },
      ].filter(a => !a.hide)
    },
  },

  watch: {
    stores() {
      this.verifyGridItensSelect()
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/sale/deliveryStoreConfig', [
      'fetchStores',
      'resetFilters',
      'setCurrentPage',
      'setPageSize',
      'clearStore',
      'addStoresThatWillBeConfigured',
      'removeStoresThatWillBeConfigured',
      'cleanSelectedStores',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchStores()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },
    async onRowClickToConfigDelivery(item) {
      const checkValue = !item.storeSelected
      // eslint-disable-next-line no-param-reassign
      item.storeSelected = checkValue
      this.onSelectStoreToConfigDelivery(checkValue, item)
    },
    async onSelectStoreToConfigDelivery(value, item) {
      try {
        if (value) {
          await this.addStoresThatWillBeConfigured(item)
        } else {
          await this.removeStoresThatWillBeConfigured(item)
          if (this.selectedStores.length < 1) this.allCheckedToDeliveryConfig = false
        }
      } catch (error) {
        this.showWarning({ message: error.message })
        // eslint-disable-next-line no-param-reassign
        item.storeSelected = !value
      }
    },
    async onSelectAllToDeliveryConfig(value) {
      let errorMessage = ''

      this.cleanSelectedStores()
      this.stores
        // .filter(item => item.paymentDate === null)
        .forEach(item => {
          try {
            // eslint-disable-next-line no-param-reassign
            item.storeSelected = value
            if (value) {
              this.addStoresThatWillBeConfigured(item)
            } else {
              this.removeStoresThatWillBeConfigured(item)
            }
          } catch (e) {
            errorMessage = e.message
            // eslint-disable-next-line no-param-reassign
            item.storeSelected = !value
          }
        })
      if (errorMessage) {
        this.showWarning({ message: errorMessage })
      }
    },
    async cleanBulkDeliveryConfig() {
      const confirmed = await this.confirm({
        text: this.$t('As lojas selecionadas serão desmarcadas.'),
      })
      if (!confirmed) return

      this.cleanSelectedStores()
      this.getData()
    },
    async onAfterChangeDeliveryConfigs(itemList) {
      await Promise.all(
        itemList.map(async item => {
          await this.removeStoresThatWillBeConfigured(item)
        })
      )
      await this.getData()
    },
    rowClass(item, type) {
      let clazz = item?.checkedToReceive ? 'selected-row' : ''
      if (item && type === 'row') {
        if (!item.paymentDate) {
          clazz += ' payment-pending'
        }
      }
      return clazz
    },
    verifyGridItensSelect() {
      const hasUnselectedRow = this.stores.some(
        row => !this.selectedStores.some(selected => row.id === selected.id)
      )
      this.allCheckedToDeliveryConfig = !hasUnselectedRow
    },
    async showBulkDeliveryConfigModal() {
      let storeIdList
      this.$nextTick(() => {
        storeIdList = this.selectedStores.map(i => i.id)
        this.$refs.deliveryStoreConfigListModal.showModal(true, storeIdList)
      })
    },
    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },
    resetFiltersLocal() {
      this.setCurrentPage(1)
      this.resetFilters()
      this.filterData()
    },
    pageSizeChange(pageSize) {
      this.setCurrentPage(1)
      this.setPageSize(pageSize)
      this.getData()
    },
    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },
    onEdit(store) {
      this.$refs.deliveryStoreConfigSidebar.show({ item: store })
    },
    onShowReadOnly(store) {
      this.$refs.deliveryStoreConfigSidebar.showReadOnly({ item: store })
    },
  },
}
</script>

<style lang="scss">
.sotre-select-row {
  & table tbody .payment-pending td {
    cursor: pointer !important;
  }
}
.check-td {
  padding-left: 14px !important;
}
</style>

<template>
  <b-modal
    id="zero_rate"
    :title="$t('Alterações de Delivery')"
    centered
    size="xl"
    no-close-on-backdrop
    hide-header-close
    hide-footer
    @hidden="cleanModalData"
  >
    <e-spinner v-if="loading" />
    <b-container
      v-else
      fluid
      :show-loading="saving"
    >
      <b-row>
        <b-col cols="12">
          <b-alert
            variant="danger"
            style="padding: 15px; text-align: center"
            show
            v-text="
              'ATENÇÃO: Todas as lojas listadas serão alteradas com as configurações selecionadas'
            "
          />
        </b-col>
      </b-row>
      <b-container fluid>
        <b-row>
          <b-col cols="4">
            <b-row>
              <b-col>
                <b-card-actions
                  :show-loading="saving"
                  :title="$t('Configurações Gerais')"
                  no-actions
                >
                  <b-row style="position: relative">
                    <FormulateInput
                      v-model="localSwitchs.MinDeliveryScheduleMinutes.isActive"
                      type="switch"
                      class="left-switch"
                    />
                    <b-col>
                      <FormulateInput
                        id="min-delivery-schedule-minutes"
                        v-model="localSwitchs.MinDeliveryScheduleMinutes.value"
                        :disabled="!localSwitchs.MinDeliveryScheduleMinutes.isActive"
                        type="text-number"
                      >
                        <template #label>
                          <span id="label">
                            <e-instructions
                              class="instruction"
                              :title="$t('Instruções')"
                              container-class="position-absolute"
                              :instruction="
                                $t('Tempo mínimo em minutos para iniciar a entrega de pedidos.')
                              "
                              style="margin-right: 0 !important"
                            />
                            <span>
                              {{ $t('Tempo mínimo para entrega') }}
                            </span>
                          </span>
                        </template>
                      </FormulateInput>
                    </b-col>
                  </b-row>
                  <b-row style="position: relative">
                    <FormulateInput
                      v-model="localSwitchs.zeroRateMinValue.isActive"
                      type="switch"
                      class="left-switch"
                    />
                    <b-col>
                      <FormulateInput
                        id="min-zero-rate-value"
                        v-model="localSwitchs.zeroRateMinValue.value"
                        :disabled="!localSwitchs.zeroRateMinValue.isActive"
                        type="text-number"
                        currency="R$"
                        :precision="2"
                      >
                        <template #label>
                          <span id="label">
                            <e-instructions
                              class="instruction"
                              :title="$t('Instruções')"
                              container-class="position-absolute"
                              :instruction="$t('TValor mínimo de pedido para taxa zero.')"
                              style="margin-right: 0 !important"
                            />
                            <span>
                              {{ $t('Valor min. p/ taxa zero') }}
                            </span>
                          </span>
                        </template>
                      </FormulateInput>
                    </b-col>
                  </b-row>
                </b-card-actions>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-card-actions
                  :show-loading="saving"
                  :title="$t('Ativar/desativar taxa zero')"
                  no-actions
                  style="position: relative"
                >
                  <FormulateInput
                    v-model="localSwitchs.zeroRateByDays.isActive"
                    type="switch"
                    style="position: absolute; top: 20px; left: 278px"
                  />
                  <b-row>
                    <b-col>
                      <template
                        v-for="dayOfWeek in daysOfWeek"
                      >
                        <config-delivery-by-stores-list
                          :key="dayOfWeek"
                          :enable-to-edit="!localSwitchs.zeroRateByDays.isActive"
                          :day-name="dayOfWeek"
                          align="center"
                          no-actions
                          @day-name="updateConfig"
                        />
                      </template>
                    </b-col>
                  </b-row>
                </b-card-actions>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-card title="Lojas selecionadas para alteração">
              <b-list-group>
                <b-list-group-item
                  v-for="store in storesReceivedInBulkPaginated"
                  :key="store.id"
                  class="list-item-group"
                >
                  {{ store.companyName }}
                  <e-button
                    variant="danger"
                    icon="x-circle"
                    title="cancelar"
                    size="sm"
                    @click="deletePrice(store)"
                  />
                </b-list-group-item>
              </b-list-group>
              <b-row class="mt-4">
                <b-col cols="6">
                  <e-pagination-summary
                    :current-page="localPaging.currentPage"
                    :per-page="localPaging.pageSize"
                    :total="localPaging.rowCount"
                    :total-on-page="localPaging.rowsInCurrentPage"
                  />
                </b-col>
                <b-col cols="6">
                  <b-pagination
                    v-model="localPaging.currentPage"
                    align="right"
                    :total-rows="localPaging.rowCount"
                    :per-page="localPaging.pageSize"
                    aria-controls="stores-to-config-list"
                    @change="pageChange"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <b-row class="mt-1 justify-content-end">
        <b-col class="d-flex justify-content-end">
          <e-button
            id="modal_receive_installment-btn_cancel"
            class="mr-1"
            variant="outline-primary"
            :text="$t('Cancelar')"
            :text-shortcuts="['ESC']"
            @click="hideModal"
          />
          <e-button
            id="modal_store_zero_rate-btn_confirm"
            variant="primary"
            icon="gear-fill"
            :text="$t('Aplicar Configurações')"
            :busy="saving"
            @click="onConfirm"
          />
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BAlert,
  BContainer,
  BCard,
  BListGroup,
  BListGroupItem,
  BPagination,
} from 'bootstrap-vue'
import { alerts, statusTypes } from '@/mixins'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import ESpinner from '@/views/components/ESpinner.vue'
import EButton from '@/views/components/EButton.vue'
import EPaginationSummary from '@/views/components/pagination/EPaginationSummary.vue'
import EInstructions from '@/views/components/EInstructions.vue'
import ConfigDeliveryByStoresList from './ConfigDeliveryByStoresList.vue'

const getInitialStoreForm = () => ({
  MinDeliveryScheduleMinutes: {
    value: null,
    isActive: false,
  },
  zeroRateMinValue: {
    value: null,
    isActive: false,
  },
  zeroRateByDays: {
    isActive: false,
    days: {
      sunday: false,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
    },
  },
})

export default {
  components: {
    BModal,
    BCardActions,
    ESpinner,
    BRow,
    BCol,
    EButton,
    BAlert,
    ConfigDeliveryByStoresList,
    BContainer,
    BCard,
    BListGroup,
    BListGroupItem,
    BPagination,
    EPaginationSummary,
    EInstructions,
  },

  mixins: [alerts, statusTypes],

  data() {
    return {
      loading: false,
      saving: false,
      isBulkReceive: false,
      storesReceivedInBulk: [],
      storesReceivedInBulkPaginated: [],
      payload: [],
      daysOfWeek: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
      localPaging: {
        currentPage: 1,
        pageSize: 10,
        rowCount: 0,
        rowsInCurrentPage: 0,
      },
      localSwitchs: getInitialStoreForm(),
    }
  },

  updated() {
    if (this.storesReceivedInBulk) {
      this.localPaging.rowCount = this.storesReceivedInBulk.length
      this.localPaging.rowsInCurrentPage = this.storesReceivedInBulkPaginated.length
    }
  },

  beforeUpdate() {
    this.storesReceivedInBulkPaginated = this.getData()
  },

  methods: {
    updateConfig({ value, dayName }) {
      this.localSwitchs.zeroRateByDays.days[dayName] = value
    },

    getData() {
      return this.storesReceivedInBulk.slice(
        (this.localPaging.currentPage - 1) * this.localPaging.pageSize,
        this.localPaging.currentPage * this.localPaging.pageSize
      )
    },

    setCurrentPage(currentPage) {
      this.localPaging.currentPage = currentPage
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    deletePrice(item) {
      const indexFound = this.storesReceivedInBulk.findIndex(
        installment => installment.id === item.id
      )
      this.storesReceivedInBulk.splice(indexFound, 1)
      this.$emit('remove-store', item)

      this.storesReceivedInBulkPaginated = this.getData()
    },

    async onConfirm() {
      this.saving = true
      try {
        if (this.isBulkReceive) {
          const payload = this.filterNonNullableKeys(this.prepareToSaveStores())

          if (Object.keys(payload).length <= 1)
            throw new Error('Por favor, preencha pelo menos uma configuração de delivery')

          if (this.storesReceivedInBulk.length === 0)
            throw new Error(
              'Por favor, selecione alguma loja para que as configurações sejam aplicadas'
            )

          await this.$http.patch('/api/store/delivery-config/bulk-receive', payload)

          this.saving = false
          this.showSuccess({ message: this.$t('Configurações aplicadas com sucesso.') })
        }

        const itemList = this.isBulkReceive ? this.storesReceivedInBulk : [this.localSwitchs]
        this.$emit('after-confirm', { itemList })
        this.hideModal()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },

    prepareToSaveStores() {
      return {
        stores: this.storesReceivedInBulk.map(store => store.id),
        MinDeliveryScheduleMinutes: this.localSwitchs.MinDeliveryScheduleMinutes.isActive
          ? this.localSwitchs.MinDeliveryScheduleMinutes.value
          : null,
        zeroRateByDays: this.localSwitchs.zeroRateByDays.isActive
          ? this.localSwitchs.zeroRateByDays.days
          : null,
        zeroRateMinValue: this.localSwitchs.zeroRateMinValue.isActive
          ? this.localSwitchs.zeroRateMinValue.value
          : null,
      }
    },

    filterNonNullableKeys(obj) {
      return Object.keys(obj).reduce((acc, key) => {
        if (obj[key] !== null && obj[key] !== undefined) {
          acc[key] = obj[key]
        }
        return acc
      }, {})
    },

    async showModal(isBulk, selectedStores) {
      this.loading = true
      this.$bvModal.show('zero_rate')
      this.isBulkReceive = isBulk

      try {
        const { data } = await this.$http.get('/api/store/delivery-config/bulk-rate-zero', {
          params: { storesIds: selectedStores },
        })

        this.storesReceivedInBulk = data
      } catch (error) {
        this.showError({ error })
        this.hideModal()
      } finally {
        this.loading = false
      }
    },

    cleanModalData() {
      this.localSwitchs = getInitialStoreForm()
    },
    hideModal() {
      this.cleanModalData()
      this.$bvModal.hide('zero_rate')
    },
  },
}
</script>

<style lang="scss" scoped>
.flex {
  align-items: center;
  display: flex;
  justify-content: space-between;

  &-gap {
    column-gap: 1rem;
  }
}

.list-item-group {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

#label {
  margin-left: 60px;

  .instruction {
    top: -2px;
  }

  span {
    margin-left: 18px;
  }
}

.left-switch {
  position: absolute;
  left: 20px;
  top: -7px;
}

/* #min-delivery-schedule-minutes_label,
#min-zero-rate-value_label {
  display: inline-block;
  margin-left: 40px;
} */
</style>

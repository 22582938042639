var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { attrs: { content: "center" } },
    [
      _c(
        "b-col",
        [
          _c("FormulateInput", {
            attrs: {
              id: "day-label",
              type: "label",
              value: _vm.$t(_vm.daysMap[_vm.dayName]),
            },
          }),
        ],
        1
      ),
      _c(
        "b-col",
        [
          _c("FormulateInput", {
            attrs: { type: "switch", disabled: _vm.enableToEdit },
            on: {
              change: function ($event) {
                return _vm.$emit("day-name", {
                  value: $event,
                  dayName: _vm.dayName,
                })
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }